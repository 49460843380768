import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  table .lflqij {
    display: none;
  }

  table {
    .cellModify {
      position: sticky;
      left: 0;
      top: 0;
      min-width: 100px;
      background-color: #57069e;
      z-index: 3;
      text-align: center;
    }
  }

  .disabled-linear-progress {
    div {
      background: transparent !important;
    }
  }

  .editable {
    background: #dcf2bb;
    /* input {
      margin-top: -20px;
    } */
  }
`;

export const ContainerLoader = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
