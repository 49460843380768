import { yupResolver } from '@hookform/resolvers/yup';
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { GridRowParams } from '@material-ui/data-grid';
import FormDefault from '~/components/FormDefault';
import InputDateRange from '~/components/Inputs/InputDateRange';
import Loja from '~/components/Loja';
import {
  BuscaProduto,
  InputMoney,
  InputSelect,
  InputText,
} from '~/components/NovosInputs';
import { moedaFormatada } from '~/components/NovosInputs/InputMoney/formatMask';
import Search from '~/components/Search';
import Separator from '~/components/Separator';
import api from '~/services/api';
import { fltRound, moneyFormat, transformAsCurrency } from '~/utils/functions';
import moment from 'moment';
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { FaTrash } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { ThemeContext } from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { v4 } from 'uuid';

import { Container, ContainerLoader } from './styles';
import { FormData } from './types';
import { schema } from './Validations';
import defaultData from './Validations/DefaultData/index.json';
import { loadingContext } from '~/context/loading';

const MySwal = withReactContent(Swal);

const ProgramacaoOferta: React.FC = () => {
  const keyRef = useRef<any>(Date.now());
  const { setLoading } = useContext(loadingContext);
  const { colors } = useContext(ThemeContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [loadingItens, setLoadingItens] = useState<boolean>(false);
  const [clearField, setClearField] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [produtosArr, setProdutosArr] = useState<any[]>([]);
  const [iniInicializado, setIniInicializado] = useState(false);
  const [produtos, setProdutos] = useState<any>([]);
  const [produtosPrecos, setProdutosPreco] = useState<any>([]);
  const [lojas, setLojas] = useState<number | number[]>();
  const [lojasReset, setLojasReset] = useState<boolean>();

  const [optionsPrecos, setOptionsPrecos] = useState<
    Array<{
      label: string;
      value: number;
    }>
  >([]);

  const scrollRef = useRef<HTMLDivElement>(null);
  const {
    register,
    handleSubmit,
    control,
    reset,
    resetField,
    setValue,
    unregister,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/preco?oferta=true');

      if (data.success) {
        const options = data.data.map((d: any) => ({
          label: d.des_preco,
          value: d.cod_preco,
        }));
        setOptionsPrecos(options);
      }

      setValue('dta_inicio', moment());
      setValue('dta_fim', moment().add(1, 'days'));
    })();
  }, []);

  useEffect(() => {
    if (errors) {
      setIniInicializado(true);
    }
  }, [errors]);

  const [formDataOld, setformDataOld] = useState<FormData>({
    dta_filtro_ini: {
      value: moment(),
      isInvalid: false,
      isRequired: true,
    },
    dta_filtro_fim: {
      value: moment().add(1, 'days'),
      isInvalid: false,
      isRequired: true,
    },
  });

  const resetformData = () => {
    setProdutosArr([]);
    setUpdate(false);
    setClearField(true);
    setLojasReset(true);
    setformDataOld({
      dta_filtro_ini: {
        value: moment(),
        isInvalid: false,
        isRequired: true,
      },
      dta_filtro_fim: {
        value: moment().add(1, 'days'),
        isInvalid: false,
        isRequired: true,
      },
    });
    keyRef.current = moment();
    setIniInicializado(false);
    setProdutosPreco([]);
    setProdutos([]);
    reset(defaultData);
    setValue('dta_inicio', moment());
    setValue('dta_fim', moment().add(1, 'days'));
    resetField('busca_produto');
  };

  const handleDataExecutar = (ini: string, fim: string, isInvalid: boolean) => {
    setformDataOld({
      ...formDataOld,
      dta_filtro_ini: {
        ...formDataOld.dta_filtro_ini,
        value: ini,
        isInvalid,
      },
      dta_filtro_fim: {
        ...formDataOld.dta_filtro_fim,
        value: fim,
        isInvalid,
      },
    });
  };

  const validaInputsParaSubmit = useCallback(
    (inputs: FormData): boolean => {
      for (let i = 0; i < Object.keys(inputs).length; i++) {
        if (
          formDataOld[Object.keys(inputs)[i] as keyof FormData].isRequired &&
          formDataOld[Object.keys(inputs)[i] as keyof FormData].isInvalid
        ) {
          return true;
        }
      }
      return false;
    },
    [formDataOld],
  );

  function formatDate(data: string): string {
    const splitedDate = data.split(' ');
    const time = splitedDate[1];
    const date = splitedDate[0];

    // reverse date to format
    let newDate: any = date.split('/');
    newDate = newDate.reverse();
    newDate = newDate.join('-');
    newDate = `${newDate} ${time}`;
    return newDate;
  }

  const onRowClick = async (param: GridRowParams) => {
    const {
      cod_programacao,
      des_programacao,
      cod_preco,
      des_preco,
      dta_final_view,
      dta_inicial_view,
    } = param.row;
    setLoader(true);
    setUpdate(true);
    setValue('cod_programacao', cod_programacao);
    setValue('des_programacao', des_programacao);

    setValue(
      'dta_inicio',
      moment(formatDate(dta_inicial_view)).format('DD/MM/YYYY HH:mm A'),
    );
    setValue(
      'dta_fim',
      moment(formatDate(dta_final_view)).format('DD/MM/YYYY HH:mm A'),
    );
    setformDataOld({
      dta_filtro_ini: {
        ...formDataOld.dta_filtro_ini,
        value: moment(formatDate(dta_inicial_view)).format(
          'DD/MM/YYYY HH:mm A',
        ),
        isInvalid: !dta_inicial_view,
        isRequired: !dta_inicial_view,
      },
      dta_filtro_fim: {
        ...formDataOld.dta_filtro_fim,
        value: moment(formatDate(dta_final_view)).format('DD/MM/YYYY HH:mm A'),
        isInvalid: !dta_final_view,
        isRequired: !dta_final_view,
      },
    });
    setValue('tipo_preco', { value: cod_preco, label: des_preco });
    const { data } = await api.post(
      '/programacao/findPrecoEmbalagemProgramacao',
      {
        id: cod_programacao,
        preco: cod_preco,
      },
    );
    const newlojas: number[] = [];

    if (data.success && data.data !== undefined) {
      const formatedProducts = data.data.map((p: any) => {
        setValue(
          `${p.cod_produto}-${p.cod_loja}-${p.cod_item_embalagem}`,
          parseFloat(p.val_prog).toLocaleString('pt-br', {
            minimumFractionDigits: 2,
          }),
        );
        return {
          ...p,
          val_venda: moneyFormat(p.val_venda),
          id: v4(),
        };
      });

      const formatedProductsId = data.data.map((p: any) => ({
        id: p.cod_produto,
        descricao: p.des_produto,
      }));
      setProdutosPreco(formatedProducts);

      setProdutos(formatedProductsId);

      for (let i = 0; i < formatedProducts.length; i++) {
        const prod = formatedProducts[i];

        if (!(newlojas.indexOf(prod.cod_loja) >= 0)) {
          newlojas.push(prod.cod_loja);
        }
      }
    }
    setShowSearch(false);
    setLoader(false);
  };

  const onCancel = () => {
    resetformData();
    setShowSearch(true);
    setProdutosPreco([]);
    setProdutos([]);
  };

  const onDelete = async () => {
    const { data } = await api.delete(
      `/programacao/${getValues('cod_programacao')}`,
    );

    if (data.success) {
      resetformData();
      setShowSearch(true);
      return toast.success(data.message);
    }
  };

  const onNew = () => {
    resetformData();
    setUpdate(false);
    setProdutosPreco([]);
  };

  function formatData(items: FieldValues) {
    const formData = {
      des_programacao: items.des_programacao,
      dta_inicial: !isUpdate
        ? getValues('dta_inicio')
        : moment(formatDate(getValues('dta_inicio'))).format('yyy-MM-DD HH:mm'),
      dta_final: !isUpdate
        ? getValues('dta_fim')
        : moment(formatDate(getValues('dta_fim'))).format('yyy-MM-DD HH:mm'),
      cod_preco: getValues('tipo_preco'),
      lojas,
    };
    delete items.des_programacao;
    delete items.dta_inicio;
    delete items.dta_fim;
    delete items.tipo_preco;
    delete items.cod_programacao;
    delete items.cod_serie;
    delete items.busca_produto;
    const products = Object.entries(items);

    const formatedProducts = [];
    let itensValidos = true;
    for (let i = 0; i < products.length; i++) {
      const [cod_produto, cod_loja, cod_item_embalagem] =
        products[i][0].split('-');
      const product = produtosPrecos.filter(
        (produto: any) =>
          produto.cod_produto === cod_produto &&
          produto.cod_loja === Number(cod_loja),
      );
      const valorVenda = parseFloat(
        String(product[0]?.val_venda).replace(/\./g, '').replace(',', '.'),
      ).toFixed(2);
      const valorProgramado = parseFloat(
        String(products[i][1]).replace(/\./g, '').replace(',', '.'),
      ).toFixed(2);
      if (
        (product.length > 0 &&
          parseFloat(valorVenda) <= parseFloat(valorProgramado)) ||
        products[i][1] === ''
      ) {
        itensValidos = false;
      }
      formatedProducts.push({
        cod_item_embalagem,
        cod_loja,
        cod_produto,
        val_prog: products[i][1],
      });
    }
    const soma = formatedProducts.reduce((acumulador, produto) => {
      if (produto.val_prog !== '') {
        const valorNumerico = parseFloat(produto.val_prog.replace(',', '.'));
        return acumulador + valorNumerico;
      }
      return acumulador;
    }, 0);

    const formatedData = Object.assign(
      formData,
      { items: formatedProducts },
      { itensValidos },
      { soma },
    );
    return formatedData;
  }

  const onSave = handleSubmit(async (data) => {
    const newData = formatData(data);

    if (produtosPrecos.length <= 0) {
      toast.warning('É necessário selecionar ao menos 1 item para a oferta.');
      return;
    }
    if (newData.soma <= 0) {
      toast.warning(
        'Ao menos um item tem que ter o valor de programação informado..',
      );
      return;
    }

    if (validaInputsParaSubmit(formDataOld)) {
      setIniInicializado(true);
      return;
    }
    if (newData.itensValidos === false) {
      setLoading(false);
      setLoader(false);
      const result = await Swal.fire({
        title: 'Confirmação',
        text: 'Existem itens sem valor programado informado ou com valor programado superior ao preço de venda. Produtos com valor zerado não serão inclusos na programação. Deseja continuar?',
        showCancelButton: true,
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#ff7b7b',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      });

      if (!result.isConfirmed) {
        return;
      }
    }

    // Valida se o intervalo entre as datas é de ao menos 15 minutos
    if (!isUpdate) {
      const minutes = moment
        .duration(newData.dta_final.diff(newData.dta_inicial))
        .asMinutes();

      if (minutes <= 15) {
        setIniInicializado(true);
        toast.warning('Selecione um intervalo de pelo menos 15 minutos.');
        return;
      }
    }
    try {
      setLoading(true);
      if (isUpdate) {
        const codProgramacao = getValues('cod_programacao');
        setLoader(true);
        const { data: responseData } = await api.put(
          `/programacao/${codProgramacao}`,
          newData,
        );

        if (responseData.success) {
          toast.success('Programação atualizada com sucesso');
          setUpdate(false);
          resetformData();
          setShowSearch(true);
          setLoader(false);
          return;
        }
        return;
      }

      const { data: responseData } = await api.post('/programacao', newData);
      if (responseData.success) {
        toast.success('Programação cadastrada com sucesso');
        return resetformData();
      }
    } catch (e) {
      setLoader(false);
    }
  });

  const handleAddProduto = useCallback(
    async (val: any) => {
      const tipoPreco = getValues('tipo_preco');
      if (!tipoPreco) {
        setProdutosArr(val);
        toast.warning('Selecione uma tabela de preço');
        return;
      }

      if (val.length === 0) {
        toast.warning('Nenhum registro encontrado.');
        return;
      }

      setLoadingItens(true);

      try {
        const prod = produtos.filter((p: { id: any }) => {
          return p.id === val[0].cod_produto;
        });
        if (prod.length <= 0) {
          const options = val.map((p: any) => ({
            descricao: p.des_produto,
            id: p.cod_produto,
          }));
          const newProds = [...produtos, ...options];
          interface ProdOptions {
            descricao: string;
            id: string;
          }
          const idProdutos = options.map((p: ProdOptions) => p.id);
          const precosId = tipoPreco.value;
          if (idProdutos.length <= 0) {
            toast.warning('Nenhum registro encontrado');
            setLoadingItens(false);
            return;
          }

          const { data } = await api.post('/programacao/findPrecoEmbalagem', {
            lojas,
            produtos: idProdutos,
            precos: [precosId],
          });
          if (data.success && data.data !== undefined) {
            const formatedProducts = data.data.map((p: any) => ({
              ...p,
              val_venda: parseFloat(p.val_venda).toLocaleString('pt-br', {
                minimumFractionDigits: 2,
              }),
              id: v4(),
              val_prog: 0,
            }));
            setProdutosPreco((oldValue: any) => [
              ...oldValue,
              ...formatedProducts,
            ]);
            setProdutos(newProds);
            setProdutosArr([]);
          }
        }
        document
          .getElementById(`${val[0].cod_produto}-${val[0].cod_loja}`)
          ?.scrollIntoView();

        if (scrollRef.current) {
          if (val.length > 5) {
            scrollRef.current.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          } else {
            scrollRef.current.scrollTo({
              top:
                scrollRef.current.scrollHeight - scrollRef.current.clientHeight,
              behavior: 'smooth',
            });
          }
        }
        setLoader(false);
        setLoadingItens(false);
      } catch (error) {
        setLoader(false);
        setLoadingItens(false);
      }
      setLoadingItens(false);
    },
    [getValues, produtos, lojas],
  );

  const handleChangeProgramacao = useCallback(
    (valor, cod_produto) => {
      const listaDeProdutos = [...produtosPrecos];
      const itensParaEdicao = listaDeProdutos.filter((item: any) => {
        if (item.cod_produto === cod_produto) {
          const fValue = parseFloat(valor.replace('.', '').replace(',', '.'));
          const calc = (fValue * 100) / transformAsCurrency(item.val_venda);
          item.precos[0].margem_calculada = Number.isNaN(calc)
            ? '0'
            : fltRound(calc, 2);
          item.val_prog = valor;
        }
        return item;
      });
      setProdutosPreco(itensParaEdicao);
    },
    [produtosPrecos],
  );
  const handleRemoveProduto = useCallback(
    (id: any) => {
      MySwal.fire({
        title: `Confirmação Necessária`,
        text: 'Deseja remover este item da programação?',
        showCancelButton: true,
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#ff7b7b',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const prodFind = produtosPrecos.find((p: any) => p.id === id);
          const prods = produtosPrecos.filter((p: any) => p.id !== id);
          setProdutosPreco(prods);

          if (prodFind) {
            const filterProducts = produtos.filter(
              (p: any) => p.id !== prodFind.cod_produto,
            );
            if (produtosPrecos.length > 0) {
              const filterProducts2 = produtosPrecos.filter(
                (p: any) => p.cod_produto === prodFind.cod_produto,
              );
              const nameProdutoRemove = `${filterProducts2[0].cod_produto}-${filterProducts2[0].cod_loja}-${filterProducts2[0].cod_item_embalagem}`;

              unregister(nameProdutoRemove);
            }
            setProdutos(filterProducts);
          } else {
            if (scrollRef.current) {
              scrollRef.current.scrollTo({
                left: 0,
                behavior: 'smooth',
              });
            }
            setProdutos([]);
          }
        }
      });
    },
    [produtosPrecos, produtos, setValue, unregister],
  );

  const [prevValue, setPrevValue] = useState('');
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '' || value === '0,0') {
      event.target.value = '';
      setPrevValue('');
      return;
    }

    const regexNumber = new RegExp(/[0-9,.]+$/g);
    if (!regexNumber.test(value)) {
      event.target.value = prevValue;
      return;
    }

    const serializedValue = moedaFormatada(value);

    let valueToFloat = '';
    if (serializedValue.length < 7) {
      valueToFloat = serializedValue.replace(',', '.');
    } else {
      valueToFloat = serializedValue.replace('.', '');
      valueToFloat = valueToFloat.replace(',', '.');
    }

    if (parseFloat(valueToFloat) > 999999999) {
      event.target.value = prevValue;
      return;
    }
    setPrevValue(() => serializedValue);
    event.target.value = serializedValue;
  };

  if (loader) {
    return (
      <ContainerLoader>
        <ClipLoader color={colors.primary} />
      </ContainerLoader>
    );
  }
  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={45}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={45}
          title="Programação de Oferta"
          codigoRegistro={[
            { value: getValues('cod_programacao'), des_campo: 'Código' },
          ]}
          onCancel={onCancel}
          onClearFields={resetformData}
          onDelete={onDelete}
          onNew={onNew}
          onReturnSearch={onCancel}
          onSave={onSave}
          isUpdate={isUpdate}
        >
          <div className="row">
            <div className="col-sm-12 mt-2">
              <Loja
                isMulti
                resetLojas={lojasReset}
                onChange={(val) => {
                  setLojasReset(false);
                  setLojas(val);
                }}
              />
            </div>
            <div className="col-sm-12">
              <Separator labelText="Dados da Programação" sidePadding="0px" />
            </div>

            <div className="col-sm-12 col-md-6">
              <InputText
                label="Descrição"
                maxLength={50}
                placeholder="Informe a Descrição"
                name="des_programacao"
                register={register}
                toLowerCase={false}
                disabled={false}
                isError={!!errors.des_programacao}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <InputSelect
                label="Tabela de Preço"
                placeholder="Selecione..."
                name="tipo_preco"
                register={register}
                isError={iniInicializado && !!errors.tipo_preco}
                control={control}
                options={optionsPrecos}
                changeSelected={(selected) => {
                  setIniInicializado(false);
                  setValue('tipo_preco', selected);
                  if (produtosArr.length > 0) {
                    handleAddProduto(produtosArr);
                  }
                }}
              />
            </div>
            <div
              className="col-sm-12 col-md-6"
              title="Adicione um interval de ao menos 15 minutos"
            >
              <InputDateRange
                dataIni={formDataOld.dta_filtro_ini.value}
                key={keyRef.current}
                dataFim={formDataOld.dta_filtro_fim.value}
                labelText="Intervalo"
                isRequired={formDataOld.dta_filtro_ini.isRequired}
                setInvalid={formDataOld.dta_filtro_ini.isInvalid}
                iniInicializado={iniInicializado}
                onChange={(ini: string, fim: string, isInvalid = true) => {
                  handleDataExecutar(ini, fim, isInvalid);
                  setValue('dta_inicio', ini);
                  setValue('dta_fim', fim);
                }}
                isDisabled={isUpdate}
              />
            </div>
            <div className="col-sm-12">
              <Separator
                labelText="Relação dos Produtos Vinculados"
                sidePadding="0px"
              />
            </div>
          </div>
          <div style={{ zIndex: 1 }}>
            <BuscaProduto
              label="Buscar por"
              placeholder="Selecione..."
              name="busca_produto"
              register={register}
              isError={!!errors.busca_produto}
              control={control}
              buscaNasLojas={lojas as number[]}
              customOptions={{
                buscarPor: ['Produto', 'Check-List', 'Marca'],
                showSelecionarItensContendo: false,
                buscaPadrao: 'Produto',
              }}
              getProduct={(selected: any) => {
                handleAddProduto(selected);
                setClearField(false);
              }}
              setValue={setValue}
              resetField={clearField ? resetField : undefined}
              style={{ zIndex: 1 }}
            />
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 mt-4" style={{ zIndex: 0 }}>
              <LinearProgress
                className={loadingItens ? '' : 'disabled-linear-progress'}
                style={{
                  width: '100%',
                  top: '60px',
                  height: '5px',
                  margin: 'auto',
                  // zIndex: 4,
                  background: loadingItens ? '#c2a0f8' : 'transparent',
                  color: loadingItens ? '#8850BF' : 'transparent',
                }}
              />
              <TableContainer
                style={{ maxHeight: 490, position: 'relative' }}
                ref={scrollRef}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className="cellModify">Loja</TableCell>
                      <TableCell className="cellModify">Código</TableCell>
                      <TableCell
                        className="cellModify"
                        style={{
                          minWidth: '200px',
                        }}
                      >
                        Descrição
                      </TableCell>
                      <TableCell className="cellModify">Emb.</TableCell>
                      <TableCell className="cellModify">Quant.</TableCell>
                      <TableCell className="cellModify">Venda</TableCell>
                      <TableCell className="cellModify">Últ. Oferta</TableCell>
                      <TableCell
                        className="cellModify"
                        style={{
                          minWidth: '150px',
                        }}
                      >
                        Valor Programado
                      </TableCell>
                      <TableCell
                        className="cellModify"
                        style={{
                          minWidth: '150px',
                          backgroundColor: 'red',
                        }}
                      >
                        % Margem Ref.
                      </TableCell>
                      <TableCell
                        className="cellModify"
                        style={{
                          minWidth: '150px',
                        }}
                      >
                        % Margem Calc.
                      </TableCell>
                      <TableCell className="cellModify">Ação</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody id="tableBottom" style={{ height: '100px' }}>
                    {produtosPrecos.map((prod: any) => {
                      return (
                        <TableRow
                          key={`${prod.cod_produto}-${prod.cod_loja}`}
                          id={`${prod.cod_produto}-${prod.cod_loja}`}
                        >
                          <TableCell align="center">{prod.cod_loja}</TableCell>
                          <TableCell align="center">
                            {prod.cod_produto}
                          </TableCell>
                          <TableCell align="center">
                            {prod.des_produto}
                          </TableCell>
                          <TableCell align="center">
                            {prod.des_unidade}
                          </TableCell>
                          <TableCell align="right">
                            {prod.qtd_embalagem}
                          </TableCell>

                          <TableCell align="right">{prod.val_venda}</TableCell>
                          <TableCell align="right">
                            {moneyFormat(prod.val_ult_oferta)}
                          </TableCell>
                          <TableCell className="editable">
                            <InputMoney
                              min={0.01}
                              decimals={999999}
                              name={`${prod.cod_produto}-${prod.cod_loja}-${prod.cod_item_embalagem}`}
                              placeholder="0,00"
                              register={register}
                              isError={false}
                              showIcon
                              onChange={(e: any) => {
                                if (e.target.value.includes('-') === true) {
                                  e.target.value = e.target.value.replace(
                                    '-',
                                    '',
                                  );
                                }
                                setValue(
                                  `${prod.cod_produto}-${prod.cod_loja}-${prod.cod_item_embalagem}`,
                                  e.target.value,
                                );
                                onChange(e);
                                handleChangeProgramacao(
                                  e.target.value,
                                  prod.cod_produto,
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell align="right">
                            {moneyFormat(prod.precos[0].r_val_margem_ref)}
                          </TableCell>
                          <TableCell align="right">
                            {moneyFormat(prod.precos[0].margem_calculada)}
                          </TableCell>
                          <TableCell align="center">
                            <FaTrash
                              className="text-danger"
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleRemoveProduto(prod.id)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {produtosPrecos.length === 0 && (
                    <div
                      style={{
                        left: '0',
                        right: '0',
                        bottom: '30px',
                        marginTop: '0px',
                        textAlign: 'center',
                        position: 'absolute',
                      }}
                    >
                      Nenhum registro informado
                    </div>
                  )}
                </Table>
              </TableContainer>
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default ProgramacaoOferta;
