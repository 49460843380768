import * as yup from 'yup';
import { transformAsCurrency } from '~/utils/functions';

export const schema = yup
  .object({
    des_programacao: yup
      .string()
      .trim()
      .required()
      .test((value: any) => {
        return value.trim() !== '';
      }),
    tipo_preco: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
  })
  .required();
